<template>
    <div class="top-feedback">
        <MoleculeMatrixNosRadioButton
            v-if="nosTabSelected"
            :active-index="activeIndex"
            @update:quick-selection="updateShowOrderTarget"
        />

        <div v-if="!isMatrixTable" class="top-feedback__save">
            <div v-if="loading" class="top-feedback__save-container">
                <AtomIcon name="loader-ring" class="top-feedback__loader top-feedback__icon" />
                <span class="top-feedback__text">{{ loadingText ?? $t('Matrix.saving') }}</span>
            </div>
            <div v-if="loadingSuccess" class="top-feedback__save-container">
                <AtomIcon name="check" class="top-feedback__check top-feedback__icon" />
                <span class="top-feedback__text">{{ successText ?? $t('Matrix.quantities_saved') }}</span>
            </div>
            <div v-if="loadingErr" class="top-feedback__save-container">
                <AtomIcon name="alert-triangle" class="top-feedback__err top-feedback__icon" />
                <span class="top-feedback__text">{{ failedText ?? $t('Matrix.quantities_save_err') }}</span>
            </div>
        </div>

        <AtomIcon
            v-if="showPackagingUnitInfo"
            class="alert-icon"
            bolding="2"
            name="alert-triangle"
            width="24"
            @mouseenter="openPackagingUnitInfoTooltip($event.target)"
            @mouseleave="closePackagingUnitInfoTooltip"
        />

        <AtomTooltip :element="tooltipElement" :offset-y="10" tooltip-type="absolute">
            {{ $t('Matrix.nos_min_quantity_packaging_info') }}
        </AtomTooltip>
    </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
    load?: boolean
    loadSuccess?: boolean
    loadErr?: boolean
    loadingText?: string
    successText?: string
    failedText?: string
    nosTabSelected?: boolean
    showOrderTarget?: boolean
    isMatrixTable: boolean
    packagingUnitCount?: number
}>()

const emit = defineEmits<{
    (e: 'update:showOrderTarget', orderTarget: boolean): void
}>()

const { $t } = useNuxtApp()
const loading = ref(false)
const loadingSuccess = ref(false)
const loadingErr = ref(false)
const checkDelay = 2000
const activeIndex: Ref<number> = ref(props.showOrderTarget ? 0 : 1)
let successTimeout: string | number | NodeJS.Timeout | undefined
let errorTimeout: string | number | NodeJS.Timeout | undefined
const tooltipElement: Ref<null | HTMLElement> = ref(null)
const isPackagingUnitInfoVisible = () => {
    return props.nosTabSelected && props.packagingUnitCount && props.packagingUnitCount > 1 && !props.showOrderTarget
}

const showPackagingUnitInfo = ref(isPackagingUnitInfoVisible())
const clearTimeouts = () => {
    clearTimeout(successTimeout)
    clearTimeout(errorTimeout)
}

const updateShowOrderTarget = (index: number) => {
    activeIndex.value = index
    emit('update:showOrderTarget', activeIndex.value === 0)
}

const openPackagingUnitInfoTooltip = (element: HTMLElement) => {
    tooltipElement.value = element
}

const closePackagingUnitInfoTooltip = () => {
    tooltipElement.value = null
}

watch(
    () => props.load,
    () => {
        if (props.load) {
            clearTimeouts()
            loadingSuccess.value = false
            loadingErr.value = false
            loading.value = true
        }
    }
)

watch(
    () => props.showOrderTarget,
    () => {
        showPackagingUnitInfo.value = isPackagingUnitInfoVisible()
    }
)

watch(
    () => props.loadSuccess,
    () => {
        if (props.loadSuccess) {
            loading.value = false
            loadingErr.value = false
            loadingSuccess.value = true
            successTimeout = setTimeout(() => {
                loadingSuccess.value = false
            }, checkDelay)
        }
    }
)

watch(
    () => props.loadErr,
    () => {
        if (props.loadErr) {
            loading.value = false
            loadingSuccess.value = false
            loadingErr.value = true
            errorTimeout = setTimeout(() => {
                loadingErr.value = false
            }, checkDelay)
        }
    }
)

defineExpose({
    hasState() {
        return loading.value || loadingSuccess.value || loadingErr.value
    },
})
</script>

<style lang="scss" scoped>
.top-feedback {
    height: rem(48);
    justify-content: flex-end;

    @include helper-flex-centered();

    &__save {
        margin-left: auto;
    }

    &__save-container {
        @include helper-flex-centered();
    }

    &__icon {
        margin-right: rem(5);
    }

    &__text {
        @include helper-font-size(6);
        @include helper-font-line-height(3);
        @include helper-font-weight(regular);
    }

    &__check {
        :deep(svg) {
            color: $setting-color-alert-success;
        }
    }

    &__err {
        :deep(svg) {
            color: $setting-color-alert-danger;
        }
    }

    &__loader {
        :deep(svg) {
            @include helper-effect-default-rotation();
        }
    }
}

.alert-icon {
    margin-left: sp(xs);

    :deep(svg) {
        color: $setting-color-alert-warning;
    }
}
</style>
